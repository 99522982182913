<template>
  <div class="produtos-skus">
    <header>
      <h1>Produtos e SKUs</h1>
      <!-- <p>Sincronize as produtos-skus com o catálogo da VTEX.</p> -->

      <div class="header-buttons">
        <b-button
          variant="outline-primary"
          @click="reload"
          v-b-tooltip.hover.top
          title="Atualizar lista"
          v-if="status && status.complete === false"
        >
          <b-spinner small type="grow"></b-spinner>
          Sincronizando...
        </b-button>

        <b-button variant="primary" @click="sincronizaProdutosSkus" v-else>
          Sincronizar
        </b-button>
      </div>
    </header>

    <Alerta
      type="warning"
      titulo="Importante"
      mensagem="Faça a sincronização com a o catálogo da VTEX antes de realizar alteracões na plataforma."
      idMemoria="dicaCategorias01"
    />

    <div class="page-container" v-if="products.length > 0">
      <p class="total_results" v-if="total > 0">Total: {{ total }} SKUs</p>
      <b-table striped hover :items="products" :fields="fields"></b-table>
      <hr />
      <paginate
        v-model="page"
        :page-count="last_page"
        :click-handler="changeLista"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'pagination'"
      >
      </paginate>
    </div>

    <div class="page-container" v-else>
      <p>Nenhum resultado</p>
    </div>
  </div>
</template>

<script>
import Alerta from "@/components/alerta/Alerta.vue";
import { sincronizaProdutosSkus, listarSkus } from "@/api/vtex/";
import { listarJobStatus } from "@/api/vtex/jobs";
import { filterDataHora } from "@/utils/date";
export default {
  components: {
    Alerta,
  },
  data() {
    return {
      page: 1,
      qtd: 15,
      fields: [
        {
          key: "Id",
          label: "ID da Sku",
        },
        {
          key: "ProductId",
          label: "Produto pai",
        },
        {
          key: "Name",
          label: "Nome",
        },
        {
          key: "created_at",
          label: "Criado em",
          formatter: (value) => {
            return filterDataHora(value);
          },
        },
        {
          key: "updated_at",
          label: "Última tentativa",
          formatter: (value) => {
            return filterDataHora(value);
          },
        },
      ],
    };
  },
  computed: {
    products() {
      return this.$store.state.vtex.products;
    },
    last_page() {
      return this.$store.state.vtex.products_last_page;
    },
    total() {
      return this.$store.state.vtex.products_total;
    },
    status() {
      return this.$store.state.vtex.job_status;
    },
  },
  methods: {
    sincronizaProdutosSkus,
    listarSkus,
    listarJobStatus,
    changeLista(num) {
      this.page = num;
      this.listarSkus(this.page, this.qtd);
    },
    reload() {
      this.page = 1;
      this.listarSkus(this.page, this.qtd);
    },
  },
  mounted() {
    this.listarSkus(this.page, this.qtd);
    this.listarJobStatus();
  },
};
</script>

<style lang="scss" scoped>
.produtos-skus {
  height: 100%;

  .page-container {
    margin: 30px 0;

    .sku {
      clear: both;
      font-size: 0.8rem;
    }
  }
}
</style>
