import client from "@/api";
import store from "@/store";
import { _rotaVtexJob, _rotaGetVtexJobStatus } from "@/api/_caminhosApi";

export function listarJobs(page) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("vtex/RESET_JOBS", null);
  client
    .get(`${_rotaVtexJob()}?page=${page}`)
    .then((resp) => {
      if (resp.status === 200) {
        store.commit("vtex/SET_JOBS", resp.data);
      }
    })
    .catch((erro) => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function listarJobStatus() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("vtex/SET_JOB_STATUS", null);
  client
    .get(`${_rotaGetVtexJobStatus()}`)
    .then((resp) => {
      if (resp.status === 200) {
        store.commit("vtex/SET_JOB_STATUS", resp.data);
      }
    })
    .catch((erro) => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
